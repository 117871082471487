<template>
  <UiPopup
    :model-value="modelValue"
    title="Send to SDR queue"
    primary-button-text="Send to queue"
    :loading="saving"
    secondary-button-text="Cancel"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
  >
    <div class="flex flex-col gap-6 overflow-hidden">
      <p class="px-5">Please select queue language. Leads will be send to the SDR queue.</p>
      <UiInputSelect
        v-model="language"
        :items="languages"
        name="language"
        placeholder="Choose language"
        :error="useGetFieldErrors(v$, ['language'])"
      ></UiInputSelect>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import { required, helpers } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import type { InputItem, LibraryItem, Lead } from '@/types'
import { useUiStore } from '~/store/ui'

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'input'): void
}>()

type Props = {
  modelValue: boolean
  selectedLeads: Lead[]
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  selectedLeads: () => [],
})

const language = ref(null)

const languages = ref<InputItem[]>([])

const getLanguages = async () => {
  const { data, error } = await useGetRotationQueues()
  if (error.value) {
    uiStore.showSnackBanner(error.value.message, 'error')
  } else {
    languages.value = data.value?.data?.map((l: LibraryItem) => ({ value: l.id, text: l.name })) || []
  }
}

onNuxtReady(async () => {
  await getLanguages()
})

const rules = computed(() => ({
  language: { required: helpers.withMessage('The language is required', required) },
}))

const v$ = useVuelidate(rules, {
  language,
})

const saving = ref(false)
const uiStore = useUiStore()

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) return

  saving.value = true
  const leadIds: number[] = props.selectedLeads.map((l: Lead) => l.id)

  try {
    await useSendToQueue({ language_id: language.value!, lead_ids: leadIds })
    uiStore.showSnackBanner(
      `${leadIds.length > 1 ? leadIds.length + ' leads were' : 'Lead was'} successfully sent to the queue.`,
      'success'
    )
    emits('update:modelValue', false)
    emits('input')
  } catch (error) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    saving.value = false
  }
}
</script>

<style scoped></style>
